import { Box, Button, Grid, Typography } from "@material-ui/core";
// import {Redirect} from "react-router-dom"
import SportsFootballIcon from "@material-ui/icons/SportsFootball";
import SportsBasketballIcon from '@material-ui/icons/SportsBasketball';
import {getUserTotalCoins, /*getGameInfo,*/ getTournCount} from "../../../hooks/useGame";
import React, { useEffect, useRef, useState } from "react";
import Fade from "react-reveal/Fade";
import {Link, useHistory} from "react-router-dom";
import {getLiveGames} from "../../../hooks/nbaListings";
import BetButton from "../../ui/BetButton";
import MatchButtonNFL from "../../ui/MatchButton";
import MatchButtonNBA from "../../ui/nba/MatchButton";
import PlaysHeader from "../../ui/PlaysHeader";
import { useStyles } from "../styles";
import constants from "../../../constants";
import "./Plays.css";
import {formatStartDate, utcDateStrToDate} from "../../../utils/dateUtils";

// import { magic } from "../../magic"
import closeX from "../../icons/closeX.png";
import CashoutBar from "../../ui/CashoutBar";
import axios from "axios";
import { Magic } from "magic-sdk"
import querystring from "querystring";

const NFL_IS_ENABLED = false
const NBA_IS_ENABLED = true

export const magic = new Magic('pk_live_4E42AB01BCFF3A8B', {
  network: {
    rpcUrl: 'https://rpc.ankr.com/chiliz',
    chainId: 88888,
  }
});

function Plays({token, setGameType, setIsShowBottomBar, setSelectedTabIdx, setSportInApp}) {
  const [showGames, setShowGames] = useState(true);
  const [showNflGames, setShowNflGames] = useState(false);
  const [showNbaGames, setShowNbaGames] = useState(false);
  const [gameStatus, setGameStatus] = useState({});
  // const [nbaGameStatus1, setNbaGameStatus1] = useState({});
  // const [nbaGameStatus2, setNbaGameStatus2] = useState({});
  // const [title, setTitle] = useState("IN PLAY...");
  const [nflTournCntText, setNflTournCntText] = useState("LOADING");
  const [nflLiveGamesArr, setNflLiveGamesArr] = useState([]);
  const [nbaTournCntText, setNbaTournCntText] = useState("LOADING");
  const [nbaLiveGamesArr, setNbaLiveGamesArr] = useState([]);

  const [upcomingGamesArr, setUpcomingGamesArr] = useState([]); // NBA only right now, but only if
                                                                          // we aren't using tournaments
  const [selectedSport, setSelectedSport] = useState(null);

  // only used if we aren't linking sport button to Tournaments page
  const [lastOutcome, setLastOutcome] = useState({});

  const [gameTypeSelected, setGameTypeSelected] = useState("");
  const [showInviteCodeModal, setShowInviteCodeModal] = useState(false);
  const [accounts, setAccounts] = useState(null);
  const [showCashoutBar, setShowCashoutBar] = useState(false);
  const [redeemIsSubmitting, setRedeemIsSubmitting] = useState(false);

  const history = useHistory();
  const classes = useStyles();

  const sports = [
    // {id: 1, content: 'NFL',  icon: <SportsFootballIcon style={{color: !showGames? '#a68537' : '#FDC934'}}/>,  },
    // {id: 2, content: 'NBA',  icon: <SportsBasketballIcon style={{color: !showGames? '#a68537' : '#FDC934'}}/>,  }
  ];

  if (NFL_IS_ENABLED)
    sports.push({id: 1, content: constants.SPORT_FOOTBALL,  icon: <SportsFootballIcon style={{color: !showNflGames? '#a68537' : '#FDC934'}}/>,  })
  if (NBA_IS_ENABLED)
    sports.push({id: 2, content: 'PRO BASKETBALL',  icon: <SportsBasketballIcon style={{color: !showNbaGames? '#a68537' : '#FDC934'}}/>,  })


  let interval = useRef();
  let nbaGameInterval1 = useRef();
  // let nbaGameInterval2 = useRef();
  let nbaLiveGamesRefreshInterval = useRef();

  const fetchGameStatusData = (queryParams) => {
    // console.log('fetchGameStatusData')
    // getGameInfo(queryParams)
    //     .then((response) => {
    //       // console.log('Response = ', response)
    //       // console.log('Response.data = ', response.data)
    //       // console.log('Response')
    //       if (response && response.data)
    //         // setGameStatus(response.data);
    //         processCurrNflGamesResponse(response.data);
    //     }).catch((error) => {
    //       console.log("Error getting the game status:: ", error);
    //     });
    getTournCount().then(response => {
      // console.log('response = ', response)
      if (response && response.data && (response.data.live_count > 0 || response.data.upcoming_count > 0)) {
        // setTitle("IN PLAY...");
        if (response.data.live_count > 0) {
          setNflTournCntText(response.data.live_count + " LIVE")
        } else {
          setNflTournCntText(response.data.upcoming_count + " UPCOMING")
        }
      } else {
        // setTitle("PAUSED")
        setNflTournCntText("0 LIVE")
      }
    })
  }

  // const fetchNbaGameStatusData1 = () => {
  //   getNbaGameProbabilities('atl')
  //       .then((response) => {
  //         // console.log('fetchNbaGameStatusData1 response = ', response)
  //         if (response.data)
  //           setNbaGameStatus1(response.data);
  //       }).catch((error) => {
  //         console.log("Error getting the game status:: ", error);
  //       });
  // }
  //
  // const fetchNbaGameStatusData2 = () => {
  //   getNbaGameProbabilities('uta')
  //       .then((response) => {
  //         if (response.data)
  //         setNbaGameStatus2(response.data);
  //       }).catch((error) => {
  //         console.log("Error getting the game status:: ", error);
  //       });
  // }
  //
  // const getNbaGameStatusInfo1 = () => {
  //   fetchNbaGameStatusData1();
  //   nbaGameInterval1.current  = setInterval(() => {
  //     fetchNbaGameStatusData1();
  //   }, 2000)
  // }
  //
  // const getNbaGameStatusInfo2 = () => {
  //   // fetchNbaGameStatusData2();
  //   // nbaGameInterval2.current  = setInterval(() => {
  //   //   fetchNbaGameStatusData2();
  //   // }, 2000)
  // }

  const getGameStatusInfo = (queryParams) => {
    // console.log('getGameStatusInfo')
    fetchGameStatusData(queryParams);
    interval.current  = setInterval(() => {
      fetchGameStatusData(queryParams);
    }, 3000)
  }

  const fetchLiveNBAGames = () => {
    // console.log('fetchLiveNBAGames')
    getLiveGames(token)
        .then(response => {
          // console.log('live games response = ', response)
          // if (response.data)
          //   setLiveGamesArr(response.data)
          processTodaysNBAGamesResponse(response)
        }).catch((error) => {
          console.log("Error getting the list of live NBA games:: ", error);
          if (error?.response?.status === 403 || error?.response?.status === 400) {
            localStorage.removeItem("token");
            window.location = "/"
          }
        });
  }

  const getLiveNBAGames = () => {
    fetchLiveNBAGames();
    nbaLiveGamesRefreshInterval.current  = setInterval(() => {
      fetchLiveNBAGames();
    }, 4000)
  }

  // const processCurrNflGamesResponse = (response) => {
  //   if (!response || !response.live_play_situations || response.live_play_situations === 0) {
  //     setLiveGamesArr([])
  //     setTitle("PAUSED");
  //     setLiveGames("0 LIVE");
  //   } else {
  //     setLiveGamesArr(response.live_play_situations)
  //     setLastOutcome(response.last_outcome)
  //
  //     setLiveGames(response.live_play_situations.length + " LIVE");
  //   }
  //   setGameStatus(response)
  // }

  const processTodaysNBAGamesResponse = (response) => {
    // console.log('processLiveGamesResponse = ', response)
    if (response.data && response.data.games && response.data.games.length > 0) {
      const upcomingGames = []
      const liveGames = []
      for (let i = 0; i < response.data.games.length; ++i) {
        const game = response.data.games[i]
        // console.log('loop game = ', game)

        // if (game["vTeam"]["triCode"] !== 'MIA')
        //   continue

        let quarter
        let is_upcoming = false
        let is_active = false
        if (game["isGameActivated"]) {
          is_active = true
          if (game["quarter"] === 0) {
            quarter = 'About to start'
            is_upcoming = true
          } else {
            quarter = game["quarter"]
          }
        } else if (game["quarter"] === 4) {
          quarter = "Final"
        } else if (!game['endTimeUTC']) {
          // console.log('blah game = ', game)
          // quarter = game["startTimeEastern"]
          if (game['startDatetimeUtc'])
            quarter = 'Starts ' + formatStartDate(utcDateStrToDate(game['startDatetimeUtc']))
          is_upcoming = true
        }

        if (is_active || is_upcoming) {
          // console.log('game["gameId"] = ', game["gameId"])
          const min_game_obj = {
            away: game["away"],
            home: game["home"],
            away_points: game["awayPoints"] || 0,
            home_points: game["homePoints"] || 0,
            quarter: quarter,
            quarterRaw: game["quarter"],
            clock: game["isGameActivated"] ? game["clock"] : '',
            id: game["gameId"],
            gameId: game["gameId"],
            // startDateEastern: game['startDateEastern'],
            lastOutcomeFormatted: game['lastOutcomeFormatted']
          }

          // min_game_obj['id'] = '0042000303'
          // min_game_obj['quarterRaw'] = 4

          if (is_upcoming) {
            upcomingGames.push(min_game_obj);
            // console.log('adding to upcoming')
          } else if (is_active) {
            liveGames.push(min_game_obj);
            // console.log('adding to liveGames: ', min_game_obj)
          }
        }
      }
      // console.log('upcomingGames = ', upcomingGames)
      // const games = response.data.games.map(game => {
      //   let quarter
      //   if (game["isGameActivated"]) {
      //     if (game["period"]["current"] === 0) {
      //       quarter = 'About to start'
      //     } else {
      //       quarter = game["period"]["current"]
      //     }
      //   } else if (game["period"]["current"] === 4) {
      //     quarter = "Final"
      //   } else{
      //     quarter = game["startTimeEastern"]
      //   }
      //   return {
      //     away: game["vTeam"]["triCode"],
      //     home: game["hTeam"]["triCode"],
      //     away_points: game["vTeam"]["score"] || 0,
      //     home_points: game["hTeam"]["score"] || 0,
      //     quarter: quarter,
      //     clock: game["isGameActivated"] ? game["clock"] : ''
      //   }
      // })

      // games.push({
      //   away: 'PHX',
      //   home: 'LAC',
      //   away_points: 100,
      //   home_points: 200,
      //   quarter: 1,
      //   clock: '14:00'
      // })
      // console.log('Games = ', games)
      // console.log('liveGames = ', liveGames)
      setUpcomingGamesArr(upcomingGames)
      setNbaLiveGamesArr(liveGames)
      if (liveGames.length === 0) {
        if (upcomingGames) {
          setNbaTournCntText(upcomingGames.length + " UPCOMING");
        } else {
          setNbaTournCntText("0 UPCOMING");
        }
      } else
        setNbaTournCntText(liveGames.length + " LIVE");
    } else {
      setNbaLiveGamesArr([])
      // setLiveGames("0 LIVE");
      setNbaTournCntText("0 UPCOMING");
    }
  }

  const toggleShowGames = (sport) => {
    // console.log('toggleShowGames, sport = ', sport)
    if (selectedSport && sport.id === selectedSport.id) {
      setGameType("");
      setGameTypeSelected("");
      setSelectedSport(null)
    } else {
      // seems like we don't need to set three variables here:
      // * sport
      // * gameType
      // * gameTypeSelected
      setSelectedSport(sport)

      if (sport.id === 1) {
        setGameType("NFL");
        setGameTypeSelected("NFL");
        localStorage.setItem("gameSelected", "NFL");
      } else if (sport.id === 2) {
        setGameType("NBA");
        setGameTypeSelected("NBA");
        localStorage.setItem("gameSelected", "NBA");
      }
    }
    if (sport.id === 1) {
      setShowNflGames(!showNflGames)
      setShowNbaGames(false)
    } else {
      setShowNbaGames(!showNbaGames)
      setShowNflGames(false)
    }


  }

  const handleNbaGameSelected = (nbaGameType, game) => {
    setGameType(nbaGameType);
    console.log('handleNbaGameSelected nbaGameType = ', nbaGameType)
    console.log('handleNbaGameSelected game = ', game)
    localStorage.setItem("gameSelected", nbaGameType);

    // console.log('gameSelected2 = ', game)
    localStorage.setItem("gameNba", JSON.stringify(game));

    clearPollingCalls()
  }

  const handleNflGameSelected = (game) => {
    // setGameType(nbaGameType);
    // console.log('handleNflGameSelected = ', game)
    // localStorage.setItem("gameSelected", nbaGameType);

    // console.log('gameSelected2 = ', game)
    localStorage.setItem("gameNfl", JSON.stringify(game));

    clearPollingCalls()
  }

  const clearPollingCalls = () => {
    console.log('clearPollingCalls')
    if (interval && interval.current)
      clearInterval(interval.current)
    if (nbaGameInterval1 && nbaGameInterval1.current)
      clearInterval(nbaGameInterval1.current)
    if (nbaLiveGamesRefreshInterval && nbaLiveGamesRefreshInterval.current) {
      clearInterval(nbaLiveGamesRefreshInterval.current)
    }
  }

  useEffect(() => {
    setIsShowBottomBar(true)
    setSelectedTabIdx(0)
    // clear this out - primary motivation here is if a user selected a
    // team on a screen after this one and pressed back to return here
    localStorage.setItem("team", null)
    localStorage.setItem("sport", null)
    checkToken()
  }, [])

  // useEffect(() => {
  //   if(showGames) {
  //     setGameTypeSelected("");
  //   }}, [showGames]);

  useEffect(() => {
    let isMounted = true;
    // console.log('useEffect, token = ', token)
    if (token) {
      if (token.id) {
        let queryParams = { params: { user_id: token.id } }
        if (isMounted) {

          getUserTotalCoins(token)
              .then((response) => {
                console.log('getUserTotalCoins Response = ', response)
                // console.log('Response.data = ', response.data)
                // console.log('Response')
                if (response && response.data)
                    // setTotalCoins(response.data?.total_coins || 0);
                  setGameStatus({
                    user: {
                      total_coins: response.data?.total_coins || 0
                    }
                  })
              }).catch((error) => {
            console.log("Error getting the game status:: ", error);
          });

          if (NFL_IS_ENABLED)
            getGameStatusInfo(queryParams);
          if (NBA_IS_ENABLED) {
            // getNbaGameStatusInfo1()
            // getNbaGameStatusInfo2()

            getLiveNBAGames()
          }
        }
        // return () => {
        //   isMounted = false ;
        //   clearInterval(interval.current)
        //   clearInterval(nbaLiveGamesRefreshInterval.current)
        // };
        return function cleanup() {
          console.log('Plays.jsx cleanup')
          isMounted = false ;
          clearPollingCalls()
        }
      }
    } else {
      // history.push("/")
    }

  }, [token]);

    useEffect(() => {
      // console.log('useEffect33, token = ', token)
      // console.log('Game Status = ', gameStatus)
      // if(gameStatus.bets_live === 1) {
      //   setTitle("IN PLAY...");
      //   let numLiveGames = 0
      //   console.log('liveGamesArr = ', liveGamesArr)
      //   if (liveGamesArr && liveGamesArr.length > 0) {
      //     for (let i = 0; i < liveGamesArr.length; ++i) {
      //       const game = liveGamesArr[i]
      //
      //       // if (game['clock'] !== '') // for NBA
      //         ++numLiveGames
      //     }
      //   }
      //   setLiveGames(numLiveGames + " LIVE");
      // } else {
      //   setTitle("PAUSED");
      //   setLiveGames("0 LIVE");
      // }
    }, [gameStatus]);

    // console.log('token = ' + token)
  // if (!token)
  //   return <Redirect to="/" />

  function checkToken() {
    const userToken = localStorage.getItem("token");
    if (userToken) {
      let values = userToken.split(";");
      if (new Date(values[1]) < new Date()) {
        localStorage.removeItem("token");
        window.location = "/"
        console.log('Expired token')
        return false
      } else {
        // const token = JSON.parse(values[0]);
        // setToken(token);
      }
    } else {
      window.location = "/"
      console.log('No userToken')
      return false
    }
    return true
  }

  function _showCashoutBar() {
    console.log('_showCashoutBar')
    // _closeInviteCodeModal()
    setShowCashoutBar(true)
  }

  function _closeInviteCodeModal() {
    setShowInviteCodeModal(false)
  }

  async function onCashoutSubmit(cashoutAmount) {
    // setShowCashoutBar(false)
    // console.log('Cashout Amount: ', cashoutAmount)

    const headers = {
      'Authorization': 'Bearer ' + token.access
    }

    // console.log(accounts)
    setRedeemIsSubmitting(true)

    if (!accounts || accounts.length < 1) {
      await _connectMagicHelper()
      if (!accounts || accounts.length < 1) {
        setRedeemIsSubmitting(false)
        return
      }
    }

    const data = {
      "redemption_coins_amount": cashoutAmount,
      "wallet_address": accounts[0]
    }

    axios.post( constants.API_HOST + '/app/send_redemption_email', querystring.stringify(data), {
      headers
    }).then(response => {
      // console.log('Redemption Email Submit Response: ', response)
      if (response?.data?.success === 1) {
        alert("Success! Redemption Request Has Been Sent. Please allow 24 hours for your request to be processed.")
      } else {
        alert(response?.data?.error_message)
      }
      setRedeemIsSubmitting(false)
      setShowCashoutBar(false)
    }).catch(error => {
      setRedeemIsSubmitting(false)
      alert("There was a server error. Please try again or contact info@playcaller.io")
      // console.log(error)
      setShowCashoutBar(true)
    })
  }

  function renderInviteCodeModal() {
    const doInformationOnly = true
    const whiteBoxHeight = doInformationOnly ? "300px" : "276px"
    return (<div style={{width: '100%', height: '100vh', position: 'absolute', top: 0, left: 0}}>
      <div style={{display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        height: '100vh'
      }}>
        <div style={{width: '50%', minWidth: '400px', height: whiteBoxHeight, backgroundColor: 'rgba(250, 250, 250, 1)', borderRadius: 6}}>
          <div style={{display: 'flex', width: 'calc(100% - 25px)', height: '20px', marginTop: "16px", paddingRight: "25px", alignItems: 'flex-end', justifyContent: 'flex-end', flexDirection: 'row'}}>
            <a href={"#"} style={{display: "block"}} onClick={_closeInviteCodeModal}>
              <img src={closeX} style={{width: "14px", height: "14px"}}/>
            </a>
          </div>
          <div style={{width: '100%', textAlign: "center", height: "20px", fontFamily: "Kanit", letterSpacing: "0.02em", color: "rgba(30, 33, 40, 1)"}}>REDEEM COINS</div>
          <div style={{width: 'calc(100% - 40px)', marginTop: "30px", marginLeft: "20px", marginRight: "20px", marginBottom: '20px'}}>
            <ul>
              <li className={"redeemModalLi"}>Connect to your Magic wallet. It's free to create one with any email.</li>
              <li className={"redeemModalLi"}>Choose how much you want to redeem. The minimum is 20,000 coins ($20 in Chiliz or USDC).</li>
              <li className={"redeemModalLi"}>Your request will be processed within 24 hours.</li>
            </ul>
          </div>
          <div style={{width: 'calc(100% - 40px)', marginTop: "30px", marginLeft: "20px", marginRight: "20px", marginBottom: '20px'}}>
            <Button id={"redeemInModalBtn"} onClick={connectMagic}>CONTINUE</Button>
          </div>
        </div>
      </div>
    </div>)
  }

  function onSportSelected(sport) {
    // console.log('onSportSelected, sport = ', sport)
    if (checkToken()) {
      // if (sport.content === constants.SPORT_FOOTBALL) {
        // console.log('Token = ', token);
        localStorage.setItem("isShowBottomBar", "true")
        history.push('/tournaments', {
          sport: sport.content
        })
        localStorage.setItem("sport", sport.content)
        setSportInApp(sport)
        clearPollingCalls()
      // } else {
      //   // setSelectedSport(sport); // do this in toggleShowGames() to prevent race-conditions
      //   toggleShowGames(sport)
      // }
    }
  }

  function createTournamentForm() {
    if (checkToken()) {
      localStorage.setItem("isShowBottomBar", "false")
      history.push('/tournament_create')
    }
  }

  const disconnectMagic = async () => {
    await magic.wallet.disconnect()
    setAccounts(null)
    setShowCashoutBar(false)
  }

  const showMagicBalances = async () => {
    await magic.wallet.showBalances()
  }

  const connectMagic = async () => {
    if (showCashoutBar) {
      setShowCashoutBar(false)
    } else {
      _closeInviteCodeModal()

      if (accounts) {
        _showCashoutBar()
      } else {
        await _connectMagicHelper()
      }
    }
  }

  const _connectMagicHelper = async () => {
    const connect_with_ui_accounts = await magic.wallet.connectWithUI().catch(e => {

    });
    if (connect_with_ui_accounts) {
      // console.log("Success: ", connect_with_ui_accounts)
      // setShowInviteCodeModal(true)
      setAccounts(connect_with_ui_accounts)
      // await magic.wallet.showBalances()
      _showCashoutBar()
    }
  }

  return (
    <Box className={classes.body}>
      <PlaysHeader data={gameStatus} showBackButton={false} showAccountIcon={true}/>
      <Grid
        container
        direction="column"
        justify="space-evenly"
        spacing={2}
        className={classes.prim}
      >
        <Grid item hidden={showNflGames || showNbaGames}>
          <Typography align="left">SELECT SPORT</Typography>
        </Grid>
        {sports.map((item) => (
          <Grid key={'game_row_' + item.id} item hidden={(selectedSport && item.id !== selectedSport.id)}>
            <BetButton
              content={item.content}
              type={"bigGame"}
              onClick={() => onSportSelected(item)}
              icon= {item.icon}
              setShowBets={setShowGames/*what is this?*/}
              badgeContent={"● " + (item.content === constants.SPORT_FOOTBALL ? nflTournCntText : nbaTournCntText)}
              className={
                (selectedSport && item.id === selectedSport.id)
                  ? classes.activeButton
                  : classes.button
              }
              spanClassName={
                (selectedSport && item.id === selectedSport.id)
                  ? classes.activeBtnSpan
                  : classes.btnSpan
              }
            />
          </Grid>
        ))}

        {(nflLiveGamesArr?.length > 0 || nbaLiveGamesArr?.length > 0) && <Grid item hidden={!showNflGames && !showNbaGames}>
          <Typography align="left" className={"gameInPlayTitle"}>{"IN PLAY..."}</Typography>
        </Grid>}
        {gameTypeSelected === "NFL" && nflLiveGamesArr?.length > 0 && <Grid item>
         <div hidden={!showNflGames}>
           {nflLiveGamesArr.map((item) => (
               <Fade bottom when={!showNflGames} key={'nfl_match_fade_'+item.id}>
                 <Link to={{pathname: "/game", home_team_code: item.home_team, away_team_code: item.away_team }}>
                   <MatchButtonNFL
                       content={item}
                       lastOutcome={lastOutcome}
                       gameType={gameTypeSelected}
                       onClick={handleNflGameSelected}
                   />
                 </Link>
               </Fade>))}
         </div>
        </Grid>}
        {gameTypeSelected === "NBA" && <Grid item><div hidden={!showNbaGames}>
          {nbaLiveGamesArr.map((item) => (
            <Fade bottom when={showNbaGames}>
              <Link to="/nba/game">
                <MatchButtonNBA
                  game={item}
                  onClick={handleNbaGameSelected}/>
              </Link>
            </Fade>))}
        </div></Grid>}
        {upcomingGamesArr && upcomingGamesArr.length > 0 && <Grid item hidden={!showNbaGames}>
          <Typography align="left" className="gameInPlayTitle">UPCOMING</Typography>
        </Grid>}
        {upcomingGamesArr && upcomingGamesArr.length > 0 && <Grid item hidden={!showNbaGames}>
          <div>
            {upcomingGamesArr.map((item) => (
              <Fade bottom when={showNbaGames}>
                <Link to="/nba/game">
                  <MatchButtonNBA
                      game={item}
                      onClick={handleNbaGameSelected}/>
                </Link>
              </Fade>))}
          </div>
        </Grid>}

        <Grid item>
          <Button onClick={createTournamentForm} id="createTournamentBtn">
            CREATE A TOURNAMENT
          </Button>
          <Button onClick={connectMagic} id="connectMagicBtn">
            REDEEM YOUR COINS
          </Button>
          {false && accounts && <Button onClick={disconnectMagic} id="connectMagicBtn">
            DISCONNECT WALLET
          </Button>}
          {accounts && <Button onClick={showMagicBalances} id="connectMagicBtn">
            ACCESS WALLET
          </Button>}
        </Grid>

        {/*<BottomBar/>*/}
      </Grid>

      {showInviteCodeModal && renderInviteCodeModal()}

      <CashoutBar
          token={token}
          gsData={gameStatus}
          hide={!showCashoutBar}
          onSubmitParent={onCashoutSubmit}
          redeemIsSubmitting={redeemIsSubmitting}
      />
    </Box>
  );
}
export default Plays;
